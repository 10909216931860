
import BaseIcon from '@/components/ui/BaseIcon.vue';
import {defineComponent} from "vue";
import { ConvertDate } from '@/components/converters/date';

export default defineComponent({
  name: 'PricesTableRow',
  components: {
    BaseIcon,
  },
  props: {
    currentItems: {
      type: Object,
      required: false,
      default: () => null
    },
    row: {
      type: Array,
      required: true,
      default: () => []
    },
    isHeader: {
      type: Boolean,
      default: () => false
    },
    descHidden: {
      type: Boolean,
      default: () => false
    },
    sort: {
      type: String,
      default: () => ''
    },
    priceFocus: {
      type: String,
      default: () => 'all'
    },
    minPrice: {
      type: Number,
      default: () => 0
    },
    maxPrice: {
      type: Number,
      default: () => 0
    },
    articleId: {
      type: Number,
      default: () => 0
    },
    numberColumns: {
      type: Number,
      default: () => 0
    }
  },
  computed: {
    getCurrentDatePrice() {
      return (index: number) => {
        if (this.currentItems[index] !== '-') {
          return ConvertDate(this.currentItems[index])
        } else {
          return this.currentItems[index]
        }
      }
    },
    getHeaderName() {
      return (item: object) => item
    },
    getBodyName() {
      return (item: object) => item
    },
    itemIsMin() {
      return (item: number) => {
        return item <= this.minPrice
      }
    },
    itemIsMax() {
      return (item: number) => {
        return item >= this.maxPrice
      }
    }
  },
  methods: {
    setSort(): any {
      this.$emit('setSort')
    }
  },
})
