
import TurnoversTableRow from "./TurnoversTableRow.vue"
import BaseError from "@/components/ui/BaseError.vue";

export default {
  name: 'TurnoversTable',
  components: {
    TurnoversTableRow,
    BaseError
  },
  data() {
    return {
      descHidden: true,
    }
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    suppliers: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    getHeaderRow(): any {
      return [
        'Бренд',
        'Артикул',
        'Описание',
        //@ts-ignore
        ...this.suppliers.map((supplier: any) => supplier.name),
        'Коэффициент',
      ]
    },
    getTableRow(): any {
      return (item: any) => {
        // ARMTEK
        // FAVORIT
        // SHATE-M
        // MSK
        // VOSHOD
        // FORUM
        // AUTOEURO
        // TECDOC
        return [
          item.cross.brand,
          item.cross.brandArt,
          // Описание
          item.prices.length ? item.prices.map((p: any) => p.description).join(', ') : '',
          //@ts-ignore
          ...this.getPricesDependingSelect(item),
          // Коэффициент
          '-',
        ]
      }
    },
  },
  methods: {
    getPricesDependingSelect(item: any): any {
      const pricesDependingSelect = [] as any

      //@ts-ignore
      this.suppliers.map((supplier: any) => {
        const foundedPrice = item.prices.find((price: any) => {
          return price.supplier === supplier.name
        })

        foundedPrice && foundedPrice.inStock
          ? pricesDependingSelect.push({
            inStock: foundedPrice.inStock,
            datePrice: foundedPrice.datePrice,
          })
          : pricesDependingSelect.push('-')
      })
      return pricesDependingSelect
    }
  }
}
