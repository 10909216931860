
import { defineComponent } from 'vue';
import BaseIcon from "@/components/ui/BaseIcon.vue";

export default defineComponent({
  name: 'ItemForEdit',
  data: () => ({
    editMode: false,
  }),
  components: {
    BaseIcon,
  },
  props: {
    getFieldValue: {
      type: Function,
      required: true
    },
    field: {
      default: () => null
    },
    param: {
      type: Object,
      default: () => null
    }
  },
  methods: {
    changeFieldValue(value: any) {
      this.$emit('changeValue', this.field, value)
    },
    cancelChangesField() {
      this.changeFieldValue(null)
      this.editMode = false
    },
  },
})
