
import DescTableRow from "./DescTableRow.vue"
import BaseIcon from '@/components/ui/BaseIcon.vue';
import {defineComponent} from "vue";

export default defineComponent({
  name: 'DescTable',
  components: {
    DescTableRow,
    BaseIcon
  },
  emits: ['setSort', 'setActive', 'changeDesc'],
  data() {
    return {
      descHidden: true,
      activeArticle: [] as any
    }
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    sort: {
      type: String,
      default: () => ''
    },
    priceFocus: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    getHeaderRow() {
      return [
        'Артикул',
        'Товарная группа',
        'Комментарий',
        'Применяемость',
        'Фокусные и ОЕ',
        'Описание',
        'Бренд',
        'Артикул бренда',
        'Мин цена, RUR'
      ]
    },
    getTableRow() {
      return (item: any) => {
        return [
          item.article,
          item.prodGroup,
          item.comment,
          item.application,
          item.focusOE,
          item.brandDesc,
          item.brands,
          item.brandArticles,
          item.brandPrices
        ]
      }
    }
  },
  methods: {
    setActive(index: number, article: number) {
      this.activeArticle[0] = index
      this.activeArticle[1] = article
    }
  }
})
