
  import BaseIcon from '@/components/ui/BaseIcon.vue';
  import {defineComponent} from "vue";

  export default defineComponent({
    name: 'DescTableRow',
    components: {
      BaseIcon,
    },
    props: {
      row: {
        type: Array,
        required: true,
        default: () => []
      },
      isHeader: {
        type: Boolean,
        default: () => false
      },
      descHidden: {
        type: Boolean,
        default: () => true
      },
      articleId: {
        type: Number,
        default: () => 0
      },
      sort: {
        type: String,
        default: () => ''
      },
      activeArticle: {
        type: Number,
        default: () => null
      },
    },
    computed: {
      getHeaderName() {
        return (item: object) => item
      },
      getBodyName() {
        return (item: object) => item
      },
      getBodyArray() {
        return (item: any) => item.split('<br>')
      },
    }
  })
