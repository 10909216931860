
import { defineComponent } from 'vue';
import ModalWrapper from "@/components/modals/ModalWrapper.vue";
import ItemForEdit from "@/components/other/ItemForEdit.vue";
import prices from "@/services/prices";
import { DatePicker } from 'v-calendar';

export default defineComponent({
  name: 'EditFilePricesModal',
  emits: ['closeModal', 'refreshData'],
  components: {ModalWrapper, ItemForEdit, DatePicker},
  data: () => ({
    changedFile: {
      supplier: null,
      username: null,
      filename: null,
      percent: null,
      date: null
    } as any
  }),
  props: {
    exceptionsField: {
      type: Array,
      required: false,
      default: () => null
    },
    itemForEdit: {
      type: Object,
      required: true,
      default: () => null
    }
  },
  computed: {
    isExceptionField() {
      return (currentField: string) => {
        const index = this.exceptionsField?.findIndex((exceptionField: any) => exceptionField === currentField)
        return index >= 0
      }
    },
    getFieldValue() {
      return (field: string) => {
        switch (field) {
          case 'percent':
            return '%'
          default:
            return null
        }
      }
    }
  },
  methods: {
    changeValue(field: string, value: string) {
      this.changedFile[field] = value
    },
    async saveChanges() {
      const updateFileData = {
        ...this.itemForEdit,
        ...(this.changedFile.supplier && {supplier: this.changedFile.supplier}),
        ...(this.changedFile.username && {username: this.changedFile.username}),
        ...(this.changedFile.filename && {filename: this.changedFile.filename}),
        ...(this.changedFile.percent && {percent: Number(this.changedFile.percent)}),
        ...(this.changedFile.date && { datePrice: new Date(this.changedFile.date).toISOString() })
      }

      const newDataFile = await prices.updatePrice(this.itemForEdit.id, updateFileData)
      this.$emit('closeModal')
      this.$emit('refreshData', newDataFile)
    }
  },
  watch: {
    itemForEdit() {
      if (this.itemForEdit && this.itemForEdit.datePrice) {
        this.changedFile.date = this.itemForEdit.datePrice
      }
    }
  }
})
