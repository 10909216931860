
  import BaseIcon from '@/components/ui/BaseIcon.vue';
  import { ConvertDate } from '@/components/converters/date';

  export default {
    name: 'TurnoversTableRow',
    components: {
      BaseIcon,
    },
    props: {
      currentItem: {
        type: Object,
        required: true,
        default: () => null
      },
      row: {
        type: Array,
        required: true,
        default: () => []
      },
      isHeader: {
        type: Boolean,
        default: () => false
      },
      descHidden: {
        type: Boolean,
        default: () => false
      },
      articleId: {
        type: Number,
        default: () => 0
      },
      numberColumns: {
        type: Number,
        default: () => 0
      }
    },
    computed: {
      getCurrentDatePrice() {
        return (currentItem: any) => {
          if (currentItem !== '-') {
            return ConvertDate(currentItem.datePrice)
          }
        }
      },
      getHeaderName() {
        return (item: object) => item
      },
      getBodyName() {
        return (item: any) => item.inStock || item
      },
    }
  }
