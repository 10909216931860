import {httpClient} from "@/httpClient";

async function getAllByType(type: string) {
  return await httpClient.get(`/product/suppliers?type=${type}`).then((response: any) => response.data)
}

async function saveAsSupplier(payload: {brandIds: Array<number | string>; type: string}) {
  return await httpClient.post(`/product/suppliers/saveFromBrand`, payload)
}

function setVisible(supplierId: number | string, visible: boolean) {
  return httpClient.patch(`/product/suppliers/visible/${supplierId}`, {visible})
}

export default {
  getAllByType,
  saveAsSupplier,
  setVisible
}
