
import { defineComponent } from 'vue';
import prices from '@/services/prices';
import { IPageableContent, Pageable, PageableSort } from '@/models/PageableModel';
import Spinner from '@/components/Spinner.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import { IDataFile } from '@/models/PricesModel';
import BaseTable from '@/components/ui/BaseTable.vue';
import { ConvertDate } from '@/components/converters/date';
import EditFilePricesModal from '@/components/modals/EditFilePricesModal.vue';
import DropdownEllipsis from '@/components/admin-panel/DropdownEllipsis.vue';
import { SupplierType } from '../types/enums';

export default defineComponent({
  name: 'SettingTable',
  props: {},
  components: {
    EditFilePricesModal,
    BaseTable,
    Spinner,
    BaseIcon,
    DropdownEllipsis
  },
  data: () => ({
    filesForTable: null as null | IDataFile[],
    pickedItemForEdit: null as null | IDataFile,
    loading: true as boolean,
    pageableTable: {
      page: 0,
      size: 20,
      sort: PageableSort.ASC,
      totalPages: 1,
    } as Pageable,
  }),
  computed: {
    getOptionsDropdownEllipsis() {
      return (item: IDataFile) => {
        return [
          {
            name: 'Скачать',
            method: () => this.downloadPrice(item)
          },
          {
            name: 'Редактировать',
            method: () => this.openModal(item)
          }
        ]
      }
    },
    headers() {
      return [
        {
          title: 'Автор',
          content: (item: IDataFile) => item.username
        },
        {
          title: 'Производитель',
          content: (item: IDataFile) => item.supplier
        },
        {
          title: 'Название файла',
          content: (item: IDataFile) => item.filename
        },
        {
          title: 'Дата',
          content: (item: IDataFile) => ConvertDate(item.datePrice)
        }
      ]
    }
  },
  methods: {
    async setSort() {
      this.loading = true

      this.pageableTable.sort = this.pageableTable.sort === PageableSort.ASC ? PageableSort.DESC : PageableSort.ASC
      console.log('thiss', this.pageableTable)
      const { content } = await this.getFilesPrices(this.pageableTable)

      this.filesForTable = content
      this.loading = false
    },
    updateListFiles(newDataFile: IDataFile) {
      if (this.filesForTable) {
        const index = this.filesForTable.findIndex((item: any) => {
          return item.id === newDataFile.id
        })
        this.filesForTable.splice(index, 1, newDataFile)
      }
    },
    openModal(itemForEdit: any) {
      this.pickedItemForEdit = itemForEdit

      setTimeout(() => {
        let element = document.getElementById(`modal-edit-file`)!
        element.style.display = 'flex'
      }, 0)
    },
    closeModal(itemForEdit: any) {
      this.pickedItemForEdit = null
      let element = document.getElementById(`modal-edit-file`)!
      element.style.display = 'none'
    },
    downloadPrice(contentItem: IDataFile) {
      const href = prices.downloadPrice(contentItem.id)
      const link = document.createElement('a');
      link.setAttribute('href', href);
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
    },
    async previous() {
      if (!!this.pageableTable.page) {
        const { content, pageable } = await this.getFilesPrices({
          page: this.pageableTable.page - 1,
        }) as IPageableContent<IDataFile>

        this.filesForTable = content
        this.pageableTable.page = pageable.pageNumber
      }
    },
    async next() {
      if ((this.pageableTable.page + 1) !== this.pageableTable.totalPages) {
        const { content, pageable } = await this.getFilesPrices({
          page: this.pageableTable.page + 1,
        }) as IPageableContent<IDataFile>

        this.filesForTable = content
        this.pageableTable.page = pageable.pageNumber
      }
    },
    async getFilesPrices(pageable: Pageable): Promise<IPageableContent<IDataFile>> {
      return await prices.getFilesPrices({...pageable, type: SupplierType.DISTRIBUTOR})
    }
  },
  async mounted() {
    const {content, totalPages} = await this.getFilesPrices(this.pageableTable)

    this.pageableTable = {
      ...this.pageableTable,
      sort: PageableSort.ASC,
      totalPages
    }

    this.filesForTable = content
    this.loading = false
  }
})
