
import PricesTableRow from "./PricesTableRow.vue"
import BaseError from "@/components/ui/BaseError.vue";
import {defineComponent} from "vue";

export default defineComponent({
  name: 'PricesTable',
  components: {
    PricesTableRow,
    BaseError,
  },
  data() {
    return {
      descHidden: true,
    }
  },
  props: {
    suppliers: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    sort: {
      type: String,
      default: () => ''
    },
    priceFocus: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    getHeaderRow(): any {
      return [
        'Бренд',
        'Артикул',
        'Описание',
        ...this.suppliers.map((supplier: any) => supplier.name),
        'Мин цена, RUR',
        'РРЦ',
        'Рекомендованная цена, RUR',
      ]
    },
    getDatePriceByBrand() {
      return (item: any) => {
        return [
            '-',
            '-',
            '-',
          ...this.getPricesDependingSelect(item).map((newItem: any) => newItem.datePrice || '-'),
            item.prices.filter((price: any) => !this.isExceptionPrice(price)).length ? Math.min.apply(null, item.prices.filter((price: any) => !this.isExceptionPrice(price)).map((p: any) => p.price)) : '-',
            // рекомендуемая
            '-',
        ]
      }
    },
    isExceptionPrice() {
      return (price: any) => {
        return ['AUTOEURO'].includes(price.supplier)
      }
    },
    getTableRow() {
      return (item: any) => {
        return [
          // Бренд
          item.cross.brand,
          item.cross.brandArt,
          // Описание
          item.prices.length ? item.prices.map((p: any) => p.description).join(', ') : '',
          ...this.getPricesDependingSelect(item).map((newItem: any) => newItem),
          // min price
          item.prices.filter((price: any) => !this.isExceptionPrice(price)).length ? Math.min.apply(null, item.prices.filter((price: any) => !this.isExceptionPrice(price)).map((p: any) => p.price)) : '-',
          //RRC
          (item && item.rrc) || '-',
          // рекомендуемая
          '-'
        ]
      }
    },
    minPrice() {
      return (item: any) => {
        return Math.min.apply(null, item.prices.map((p: any) => p.price))
      }
    },
    maxPrice() {
      return (item: any) => {
        return Math.max.apply(null, item.prices.map((p: any) => p.price))
      }
    },
    getPricesDependingSelect(): any {
      return (item: any) => {
        const pricesDependingSelect = [] as any

        this.suppliers.map((supplier: any) => {
          const foundedPrice = item.prices.find((price: any) => {
            return price.supplier === supplier.name
          })

          foundedPrice && foundedPrice.price
            ? pricesDependingSelect.push({
              datePrice: foundedPrice.datePrice,
              price: foundedPrice.price,
              rrcPrice: item && item.rrc
            })
            : pricesDependingSelect.push('-')
        })

        return pricesDependingSelect
      }
    }
  },
  methods: {
    setSort() {
      this.$emit('setSort')
    }
  },
})
